  <template>
  <v-container fluid>
    <div v-if="componentLoading && component_type == 'add'">
      <h3>>Add Customer To Deal</h3>
    </div>
    <div v-if="componentLoading && component_type == 'edit'">
      <h3>>Edit Customer</h3>
    </div>
    <p v-if="componentLoading">Loading...</p>
    <div v-if="!componentLoading && component_type == 'add'">
      <h3>Add Customer To Deal</h3>
      <br /><span style="text-transform: capitalize"
        >{{ dealData.name }} ({{ dealData.public_name }})</span
      >
    </div>
    <div v-if="!componentLoading && component_type == 'edit'">
      <h3>Edit Customer</h3>
      <br />
    </div>

    <v-form
      v-if="!componentLoading"
      ref="customer_add_form"
      v-model="formRules.valid"
    >
      <v-row>
        <v-col>
          <v-text-field
            v-model="customerData.first_name"
            label="First Name"
            :rules="formRules.required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="customerData.last_name"
            label="Last Name"
            :rules="formRules.required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="customerData.birthday"
                  label="Birthday"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="customerData.birthday"
                :active-picker.sync="activePicker"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                min="1900-01-01"
                @change="save"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="customerData.email"
            label="Email"
            :rules="formRules.required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="customerData.phone"
            label="Phone"
            :rules="formRules.phone"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="drop_down_options.applicant"
            v-model="applicant_type"
            label="Applicant Type"
            :rules="formRules.required"
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-if="show_co_applicant_fields">
        <v-col>
          <v-select
            :items="drop_down_options.co_applicant_type"
            v-model="customerData.co_applicant_type"
            label="Co Applicant Type"
            :rules="formRules.required"
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-if="show_co_applicant_fields">
        <v-col>
          <v-select
            :items="
              drop_down_options.co_applicant_relationship_to_main_applicant
            "
            v-model="customerData.co_applicant_relationship_to_main_applicant"
            label="Relationship To Main Applicant"
            :rules="formRules.required"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            v-if="!componentLoading && component_type == 'add'"
            @click="submit_form"
            >Create Customer</v-btn
          >
          &nbsp;
          <v-btn
            v-if="!componentLoading && component_type == 'edit'"
            @click="submit_form"
            >Update Customer</v-btn
          >&nbsp;
          <v-btn color="red" @click="$router.go(-1)">Back</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="error">
        <v-col>
          <v-alert type="error"><ul><li v-for="(err, index) in error" :key="index">{{err.message}}</li></ul></v-alert>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
export default {
  data() {
    return {
      error: null,
      component_type: null,
      activePicker: null,
      date: null,
      menu: false,
      applicant_type: null,
      show_co_applicant_fields: false,
      componentLoading: true,
      dealData: {},
      customerData: {
        first_name: "",
        last_name: "",
        birthday: null,
        email: "",
        phone: "",
        main_applicant: null,
        co_applicant: null,
        co_applicant_type: null,
        co_applicant_relationship_to_main_applicant: null,
        deals: [],
      },
      drop_down_options: {
        applicant: [
          {
            value: "main_applicant",
            text: "Main Applicant",
          },
          {
            value: "co_applicant",
            text: "Co-Applicant",
          },
        ],

        co_applicant_type: [
          {
            value: "applicant",
            text: "Applicant",
          },
          {
            value: "co-signer",
            text: "Co-Signer",
          },
          {
            value: "guarantor",
            text: "Gaurantor",
          },
        ],
        co_applicant_relationship_to_main_applicant: [
          {
            value: "spouse",
            text: "Spouse",
          },
          {
            value: "common-law",
            text: "Common-Law",
          },
          {
            value: "related-family-member",
            text: "Related Family Member",
          },
          {
            value: "parent",
            text: "Parent",
          },
          {
            value: "sibling",
            text: "Sibling",
          },
          {
            value: "child",
            text: "Child",
          },
          {
            value: "grandchild",
            text: "Grandchild",
          },
          {
            value: "grandparent",
            text: "Grandparent",
          },
          {
            value: "spouse",
            text: "Spouse",
          },
          {
            value: "other",
            text: "Other",
          },
        ],
      },

      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
    };
  },
  watch: {
    applicant_type(val) {
      if (val == "main_applicant") {
        this.customerData.main_applicant = true;
        this.customerData.co_applicant = false;
        this.customerData.co_applicant_type = null;
        this.customerData.co_applicant_relationship_to_main_applicant = null;
        this.show_co_applicant_fields = false;
      }
      if (val == null) {
        this.customerData.main_applicant = false;
        this.customerData.co_applicant = null;
        this.customerData.co_applicant_type = null;
        this.customerData.co_applicant_relationship_to_main_applicant = null;
        this.show_co_applicant_fields = false;
      }
      if (val == "co_applicant") {
        this.customerData.main_applicant = false;
        this.customerData.co_applicant = true;
        this.customerData.co_applicant_type = null;
        this.customerData.co_applicant_relationship_to_main_applicant = null;
        this.show_co_applicant_fields = true;
      }
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    async submit_form() {
      this.$refs.customer_add_form.validate();
      try {
        if (this.formRules.valid) {
          const formResponse = await API().post(
            "/api/internal-admin/create_customer",
            this.customerData
          );
          console.log(formResponse);
          this.$refs.customer_add_form.resetValidation();
          this.$router.push(`/dashboard/admin/deal/read/${this.dealData.id}`);
        }
      } catch (error) {
        // console.log(error.response)
        this.error = error.response.data.error.details.errors
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    loadPageData() {
      this.componentLoading = true;
      API()
        .get(`api/internal-admin/dealRead/${this.$route.params.deal_id}`)
        .then((res) => {
          this.dealData = res.data;
          this.customerData.deals.push(this.dealData.id);

          this.componentLoading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    if (this.$route.name == "deal_add_customer") {
      this.loadPageData();
      this.component_type = "add";
    }

    if (this.$route.name == "customer_edit") {
      this.component_type = "edit";
      this.loadPageData();
    }
  },
};
</script>

<style>
</style>